export const environment = {
  production: true,
  baseUrl: 'https://planogenetico.altagenetics.com.br',
  apiBaseUrl: 'https://planogenetico.altagenetics.com.br/api',
  b2cRedirectUri: 'https://planogenetico.altagenetics.com.br',
  b2cPostLogoutRedirectUri: 'https://planogenetico.altagenetics.com.br',
  b2cClientId: '0c350ba9-a290-4bbe-a8ef-1faf4cecc337',
  b2cLogin: 'altab2c.b2clogin.com',
  b2cDomain: 'altab2c.onmicrosoft.com',
  b2cApiClientId: '0c350ba9-a290-4bbe-a8ef-1faf4cecc337',
  b2cSignUpSignIn: 'B2C_1_Common_SignupSignin_v1',
  b2cResetPassword: 'B2C_1_Common_PasswordReset_v1'
};